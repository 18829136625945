import { reactive } from 'vue';
import { apiConsultGetPageList } from '@/request/api';
import { useRouter } from 'vue-router';

// 特别关注
export default () => {
    const $router = useRouter();

    const infos = reactive({
        updateList: [],
        industryList: [],
        bookList: [],
    });

    // 获取更新介绍
    async function getUpdateList() {
        const { data } = await apiConsultGetPageList({ plateId: 136 });
        const list = data?.list || [];
        infos.updateList = list.slice(0, 3);
    }

    // 获取行业资讯
    async function getIndustryList() {
        const { data } = await apiConsultGetPageList({ plateId: 137 });
        const list = data?.list || [];
        infos.industryList = list.slice(0, 5);
    }

    // 获取市场宝典
    async function getBookList() {
        const { data } = await apiConsultGetPageList({ plateId: 138 });
        const list = data?.list || [];
        infos.bookList = list.slice(0, 5);
    }

    // 资讯详情
    function toInfoDetails({ id, plateId }) {
        $router.push({ path: '/article/details/' + id, query: { plateId } })
    }

    getUpdateList();// 获取更新介绍
    getIndustryList();// 获取行业资讯
    getBookList();// 获取市场宝典

    return { infos, toInfoDetails }
}