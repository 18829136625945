<template>
    <el-dialog custom-class="activity_view_dialog" top="30px" :model-value="show" width="1200px" @close="handleClose"
        @open="handleOpen">
        <div class="dialog_header">
            <h1>展会一览{{ year }}年</h1>
            <div class="btn">
                <div class="arrow" :class="year <= minYear ? 'disabled' : ''" @click="handleYearChange(-1)">
                    <ArrowLeft class="icon" />
                </div>
                <div class="arrow" :class="year >= maxYear ? 'disabled' : ''" @click="handleYearChange(1)">
                    <ArrowRight class="icon" />
                </div>
            </div>
            <div class="close">
                <Close class="icon" @click="handleClose" />
            </div>
        </div>
        <div class="list">
            <div class="year" v-if="list.length > 0">
                <div class="month" v-for="item in list" :key="item.month"
                    :class="currentMonth === +item?.date[1] && year === currentYear ? 'active' : ''">
                    <div class="tit">
                        <div class="le">
                            <h1>{{ monthChinese.get(+item?.date[1]) }}</h1>
                            <span>（展会数：{{ item.value }}）</span>
                        </div>
                        <router-link v-if="item.value > 0" :to="{ path: '/activity', query: { date: item.month } }"
                            class="all">查看全部</router-link>
                    </div>
                    <ul>
                        <template v-for="(li, index) in item.children" :key="li.id">
                            <li class="textOverflowTwo" v-show="index < 5" :class="li.status === 0 ? 'over' : ''"
                                @click="toActivity(li)">
                                {{ li.title }}
                            </li>
                        </template>
                        <div class="more" v-if="item.value > 5">
                            <MoreFilled class="icon" />
                        </div>
                    </ul>
                </div>
            </div>
            <el-empty v-else :description="`${year}年暂无展会信息`" />
        </div>
    </el-dialog>
</template>

<script setup>
import { ArrowLeft, ArrowRight, Close, MoreFilled } from '@element-plus/icons-vue';
import { ref, computed } from 'vue';
import { apiActivGetActivOverview } from '@/request/api';
import withLoading from '@/utils/loading';

defineProps({
    show: { type: Boolean, default: true }
})

const emits = defineEmits(['close', 'toActivity'])

const year = ref(new Date().getFullYear());// 当前选择的年份，默认当前年份
const currentYear = new Date().getFullYear();// 当前实际年份
const currentMonth = new Date().getMonth() + 1;// 当前实际月份
const minYear = 2010;// 最小年份
// 最大年份
const maxYear = computed(() => {
    return currentYear + 1;
})

const list = ref([]);

const listFilter = (arr) => {
    if (arr.length === 0) return [];
    const res = [];
    for (let i = 1; i < 13; i++) {
        const idx = arr.findIndex(item => item.date[1] === i);
        if (idx === -1) {
            res.push({ date: [year.value, i], children: [], value: 0, month: `${year.value}-${i}` });
        } else {
            res.push(arr[idx]);
        }
    }
    return res;
}

const monthChinese = new Map([
    [1, '一月'], [2, '二月'], [3, '三月'], [4, '四月'], [5, '五月'], [6, '六月'], [7, '七月'], [8, '八月'], [9, '九月'], [10, '十月'], [11, '十一月'], [12, '十二月']
])

// year改变
function handleYearChange(val) {
    if (year.value <= minYear && val === -1) {
        return;
    };
    if (year.value >= maxYear.value && val === 1) {
        return;
    };
    year.value = year.value + val;
    getData();
}

// 获取展会信息列表
async function getData() {
    let { data = [] } = await withLoading(apiActivGetActivOverview)({ year: year.value });
    data = data || [];
    list.value = listFilter(data.map(item => {
        item.date = item.month.split('-').map(item => +item);
        return item;
    }))
}

// 跳转展会信息详情
function toActivity(row) {
    emits('toActivity', row);
}

// 打开
function handleOpen() {
    getData();
}

// 关闭
function handleClose() {
    emits('close');
}

</script>

<style lang="scss">
.activity_view_dialog {
    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        padding: 0;
    }

    .dialog_header {
        padding: 0 32px;
        height: 100px;
        display: flex;
        align-items: center;
        position: relative;

        h1 {
            font-size: 24px;
            color: #070A16;
        }

        .btn {
            display: flex;
            margin-left: 24px;

            .arrow {
                width: 28px;
                height: 32px;
                border: 1px solid #D9D9D9;
                margin-right: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .icon {
                    width: 1em;
                    height: 1em;
                    color: #2D2D2D;
                }

                &.disabled {
                    cursor: not-allowed;

                    .icon {
                        color: #c1c1c1;
                    }
                }
            }
        }

        .close {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;

            .icon {
                width: 1.5em;
                height: 1.5em;
            }
        }
    }

    .list {
        min-height: 700px;
        padding: 32px;
        padding-top: 0;

        .year {
            display: grid;
            grid-template-columns: repeat(4, 25%);

            .month {
                min-height: 256px;
                border-top: 1px solid #DBDBDB;

                &.active {
                    border-top: 4px solid var(--primary-color);
                }

                .tit {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px 15px 17px 15px;

                    .le {
                        display: flex;
                        align-items: flex-end;

                        h1 {
                            font-size: 20px;
                            color: #3B3D47;
                        }

                        span {
                            font-size: 12px;
                            color: #9D9EA2;
                            padding-bottom: 2px;
                        }
                    }

                    .all {
                        display: none;
                        color: #48A6FD;
                        cursor: pointer;
                        font-size: 12px;
                    }
                }

                &:hover {

                    .tit {
                        .all {
                            display: block;
                        }
                    }
                }

                ul {
                    padding: 15px;
                    padding-top: 0;
                    padding-left: 0;

                    li {
                        line-height: 30px;
                        padding-left: 35px;
                        position: relative;
                        color: #464952;
                        cursor: pointer;

                        &:hover {
                            background-color: #F1F5FA;
                        }

                        &::before {
                            display: inline-block;
                            content: '';
                            width: 4px;
                            height: 4px;
                            background-color: #005CA9;
                            border-radius: 50%;
                            position: absolute;
                            left: 15px;
                            top: 13px;
                        }

                        &.over {
                            color: #B0B1B5;

                            &::before {
                                background-color: #B0B1B5;
                            }
                        }
                    }

                    .more {
                        padding-left: 20px;

                        .icon {
                            width: 1em;
                            height: 1em;
                            color: #999;
                        }
                    }
                }
            }
        }
    }
}
</style>