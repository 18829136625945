import { ref } from 'vue';

export default () => {

    const show = ref(false);

    function open() {
        show.value = true;
    }

    function close() {
        show.value = false;
    }

    return { show, open, close }
}