<template>
    <div class="homes_container">
        <div class="left_box">
            <!-- 特别关注 -->
            <section class="new_intro" v-if="information === 'Y'">
                <div class="tit_row">
                    <h1>特别关注</h1>
                    <router-link to="/newintro">查看全部</router-link>
                </div>
                <ul v-if="infos.updateList.length">
                    <li v-for="item in infos.updateList" :key="item.id" @click="toInfoDetails(item)">
                        <div class="le">
                            <span :style="{ backgroundColor: item.cssClass || '#999' }">{{ item.consultTypeName }}</span>
                            <p class="textOverflowOne">{{ item.title }}
                            </p>
                        </div>
                        <div class="date_box">
                            <p>{{ item.createTime }}</p>
                        </div>
                        <div class="download" v-if="item.annexList && item.annexList.length > 0"
                            @click.stop="actDownloadZip(item.id, item.title)">
                            <el-tooltip class="box-item" effect="dark" content="下载" placement="bottom">
                                <i class="iconfont icon-xiazai"></i>
                            </el-tooltip>
                        </div>
                    </li>
                </ul>
                <no-data v-else msg="暂无特别关注" />
            </section>
            <!-- 订阅报告 -->
            <section class="reprot">
                <div class="tit_row">
                    <h1>订阅报告</h1>
                </div>
                <ul v-if="reportList.length">
                    <li v-for="item in reportList" :key="item.id" @click="onReport(item)">
                        <p>{{ item.name }}</p>
                    </li>
                </ul>
                <div class="page_box" v-show="reportList.length && reportTotal > reportQuery.pageSize">
                    <el-pagination background layout="prev, pager, next" :total="reportTotal"
                        :page-size="reportQuery.pageSize" v-model:current-page="reportQuery.pageNum" />
                </div>
                <no-data v-if="!reportList.length" msg="暂无订阅的报告" />
            </section>
        </div>
        <div class="right_box" v-if="information === 'Y'">
            <!-- 行业资讯 -->
            <section class="ri_item industry">
                <div class="tit_row">
                    <h1>行业资讯</h1>
                    <div class="ri">
                        <router-link to="/industryinfo">查看全部</router-link>
                    </div>
                </div>
                <ul class="scrollbar" v-if="infos.industryList.length">
                    <li v-for="item in infos.industryList" :key="item.id" @click="toInfoDetails(item)">
                        <p class="textOverflowOne">{{ item.title }}</p>
                        <div class="download" v-if="item.annexList && item.annexList.length > 0"
                            @click.stop="actDownloadZip(item.id, item.title)">
                            <el-tooltip class="box-item" effect="dark" content="下载" placement="bottom">
                                <i class="iconfont icon-xiazai"></i>
                            </el-tooltip>
                        </div>
                    </li>
                </ul>
                <no-data v-else msg="暂无行业资讯" />
            </section>
            <!-- 展会信息 -->
            <section class="ri_item activity">
                <div class="tit_row">
                    <h1>展会信息</h1>
                    <div class="ri">
                        <span class="btn" @click="activityViewDialogOpen">一览</span>
                        <span class="btn"> / </span>
                        <router-link to="/activity" class="btn">全部</router-link>
                    </div>
                </div>
                <div class="content scrollbar" v-if="Object.keys(activityList).length">
                    <div class="con_item" v-for="(item, key) in activityList" :key="key">
                        <div class="tit">{{ key }}</div>
                        <ul>
                            <template v-for="( row, index ) in  item " :key="row.id">
                                <!-- <li class="textOverflowOne" v-if="index < 3" @click="toActivity(row)"> -->
                                <li class="textOverflowOne" @click="toActivity(row)">
                                    {{ row.title }}
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
                <no-data v-else msg="暂无展会信息" />
            </section>
            <!-- 市场宝典 -->
            <section class="ri_item book">
                <div class="tit_row">
                    <h1>市场宝典</h1>
                    <div class="ri">
                        <router-link to="/valuablebook">查看全部</router-link>
                    </div>
                </div>
                <ul class="scrollbar" v-if="infos.bookList.length">
                    <li v-for="item in infos.bookList" :key="item.id" @click="toInfoDetails(item)">
                        <p class="textOverflowOne">{{ item.title }}</p>
                        <div class="download" v-if="item.annexList && item.annexList.length > 0"
                            @click.stop="actDownloadZip(item.id, item.title)">
                            <el-tooltip class="box-item" effect="dark" content="下载" placement="bottom">
                                <i class="iconfont icon-xiazai"></i>
                            </el-tooltip>
                        </div>
                    </li>
                </ul>
                <no-data v-else msg="暂无市场宝典" />
            </section>
        </div>
    </div>
    <!-- 展会信息一览弹框 -->
    <ActivityViewDialog :show="activityViewDialogShow" @toActivity="toActivity" @close="activityViewDialogClose" />
</template>

<script setup name="Home">
import ActivityViewDialog from './activity-view-dialog/ActivityViewDialog.vue';
import useActivityView from './activity-view-dialog/useActivityView';
import useInfo from './hooks/useInfo';
import useReport from './hooks/useReport';
import useActivity from './hooks/useActivity';
import useDownloadZip from './hooks/useDownloadZip';
import NoData from '@/components/common/NoData.vue';
import { getStorage } from '@/utils';

// 是否显示资讯版块
const information = getStorage('information') || 'Y';

// 资讯
const { infos, toInfoDetails } = useInfo();

// 报告列表
const { list: reportList, total: reportTotal, query: reportQuery, onReport } = useReport(information);

// 展会信息
const { activityList, toActivity } = useActivity();

// 展会信息一览
const { show: activityViewDialogShow, open: activityViewDialogOpen, close: activityViewDialogClose } = useActivityView();

// 打包下载
const { actDownloadZip } = useDownloadZip();

</script>

<style lang="scss" scoped>
.homes_container {
    display: flex;
    justify-content: center;
    height: 100%;

    .left_box {
        width: 65%;
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;

        .new_intro {
            background-color: #fff;
            border-radius: 8px;
            padding: 24px;
            margin-bottom: 32px;

            .tit_row {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h1 {
                    color: #070A16;
                    font-size: 20px;
                }

                a {
                    color: #1890FF;
                }
            }

            ul {
                padding-top: 24px;

                li {
                    height: 40px;
                    position: relative;
                    cursor: pointer;
                    padding-right: 180px;

                    &:hover {
                        background-color: #F1F5FA;
                    }

                    .le {
                        display: flex;
                        align-items: center;
                        height: 100%;

                        span {
                            line-height: 24px;
                            background-color: #ccc;
                            padding: 0 8px;
                            color: #fff;
                            margin-right: 16px;
                            font-size: 12px;
                            white-space: nowrap;
                        }

                        p {
                            color: #070A16;
                            font-size: 14px;
                        }
                    }

                    .date_box {
                        position: absolute;
                        right: 80px;
                        top: 0;
                        height: 100%;
                        display: flex;
                        align-items: center;

                        p {
                            color: #989A9E;
                            font-size: 14px;
                            white-space: nowrap;
                        }
                    }

                    .download {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        position: absolute;
                        right: 32px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .iconfont {
                            font-size: 18px;
                            color: var(--primary-color);
                        }

                        &:hover {
                            background-color: #E4EEFB;
                        }
                    }
                }
            }
        }

        .reprot {
            --title-height: 60px;
            --page-box-height: 60px;
            background-color: #fff;
            border-radius: 8px;
            flex: 1;
            display: flex;
            flex-direction: column;

            .tit_row {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 24px;

                h1 {
                    color: #070A16;
                    font-size: 20px;
                }
            }

            ul {
                padding: 0 24px;
                flex: 1;
                display: grid;
                grid-template-rows: repeat(v-bind('reportQuery.pageSize'), auto);
                row-gap: 16px;
                padding-bottom: 20px;

                li {
                    max-height: 120px;
                    border-radius: 4px;
                    padding: 0 32px;
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    align-items: center;
                    background: url(~@/assets/img/reprot_back.png) no-repeat right;
                    background-color: #f1f5fa;
                    transition: all .2s linear;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        font-size: 24px;
                        color: #292D38;
                        width: 70%;
                    }

                    &:hover {
                        background: url(~@/assets/img/reprot_back_hover.png) no-repeat right;
                        background-color: #f1f5fa;

                        p {
                            color: var(--primary-color);
                            text-shadow: 0 0 3px #fff;
                        }
                    }
                }
            }

            .page_box {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
            }
        }
    }

    .right_box {
        width: 35%;
        height: 100%;
        padding: 0 24px;
        background-color: #fff;
        // align-self: flex-start;
        overflow-y: auto;

        .ri_item {
            height: 33.3%;
            padding-top: 24px;
            border-bottom: 1px solid #D9D9D9;
            display: flex;
            flex-direction: column;

            &:last-child {
                border-bottom: none;
            }

            .tit_row {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h1 {
                    color: #070A16;
                    font-size: 20px;
                }

                .ri {

                    a,
                    .btn {
                        color: #1890FF;
                        cursor: pointer;
                    }
                }

            }

            ul {
                flex: 1;
                overflow-y: auto;
            }

            &.industry {
                ul {
                    padding-top: 13px;

                    li {
                        height: 32px;
                        padding: 0 8px;
                        cursor: pointer;
                        position: relative;
                        padding-right: 60px;
                        display: flex;
                        align-items: center;

                        p {
                            line-height: 32px;
                            font-size: 14px;
                        }

                        &:hover {
                            background-color: #F1F5FA;
                        }

                        .download {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            position: absolute;
                            right: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .iconfont {
                                font-size: 18px;
                                color: var(--primary-color);
                            }

                            &:hover {
                                background-color: #E4EEFB;
                            }
                        }
                    }
                }
            }

            &.activity {
                .content {
                    padding-top: 24px;
                    flex: 1;
                    overflow-y: auto;

                    .con_item {
                        margin-bottom: 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .tit {
                            font-size: 16px;
                            color: #070A16;
                            margin-bottom: 4px;
                        }

                        ul {
                            padding-left: 15px;
                            border-left: 1px dashed #979797;

                            li {
                                height: 32px;
                                line-height: 32px;
                                cursor: pointer;
                                font-size: 14px;
                                padding: 0 8px;

                                &:hover {
                                    background-color: #F1F5FA;
                                }
                            }
                        }
                    }
                }
            }

            &.book {
                ul {
                    padding-top: 24px;

                    li {
                        height: 32px;
                        padding: 0 8px;
                        cursor: pointer;
                        position: relative;
                        padding-right: 60px;
                        display: flex;
                        align-items: center;

                        p {
                            line-height: 32px;
                            font-size: 14px;
                        }

                        &:hover {
                            background-color: #F1F5FA;
                        }

                        .download {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            position: absolute;
                            right: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .iconfont {
                                font-size: 18px;
                                color: var(--primary-color);
                            }

                            &:hover {
                                background-color: #E4EEFB;
                            }
                        }
                    }
                }
            }

            // 滚动条样式
            .scrollbar::-webkit-scrollbar {
                width: 4;
                height: 4;
            }

            .scrollbar::-webkit-scrollbar-thumb {
                //滑块部分
                border-radius: 2;
                background-color: #f2f2f2;
            }

            .scrollbar::-webkit-scrollbar-track {
                //轨道部分
                background: none;
            }
        }
    }
}
</style>