import { computed, ref } from 'vue';
import { apiActivGetActivHomeList } from '@/request/api';
import { useRouter } from 'vue-router';
import { groupBy } from '@/utils';

// 展会信息
export default () => {

    const $router = useRouter();

    const month = computed(() => {
        const date = new Date();
        const yy = date.getFullYear();
        let mm = date.getMonth() + 1;
        if (mm < 10) {
            mm = '0' + mm;
        }
        return yy + '-' + mm;
    })
    const activityList = ref([]);

    // 首页一览
    async function getList() {
        const { data } = await apiActivGetActivHomeList({ month: month.value, aroundMonth: 2 });
        let list = data || [];
        list = list.map(item => {
            item['monthKey'] = item.month.replace(/-/, '年') + '月';
            return item;
        })
        activityList.value = groupBy(list, 'monthKey');
    }

    // 跳转展会信息详情
    function toActivity({ id, month }) {
        $router.push({ path: '/activity/details/' + id, query: { activMonth: month } })
    }

    getList();

    return { activityList, toActivity }
}