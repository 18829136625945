import { apiFileConsultZip } from '@/request/api';
import { saveAsFile } from '@/utils/download';
import { ElMessage } from 'element-plus';
import { baseURL } from '@/request/http';
import { getStorage } from '@/utils'

export default () => {

    // 资讯附件打包下载
    async function actDownloadZip(id, fileName) {
        const href = `${baseURL}${apiFileConsultZip()}?id=${id}&fileName=${fileName}&token=${getStorage('token')}`;
        saveAsFile(href, fileName);
        ElMessage.success('已加入浏览器下载队列');
    }


    return { actDownloadZip }
}